import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import AdmLayout from '@/layout/adm/AdmLayout.vue';
import ProLayout from '@/layout/pro/ProLayout.vue';
import { firebaseAuth } from '../firebaseConfig';

const host = window.location.host;
let routes;
console.log (host);

if (host == 'pro.adieuberthe.fr' || host == 'pro.local:8080'){
    routes = [ {

        path: '/',
        component: ProLayout,
        children: [
            {
                
                    path: '/dashboard',
                    name: 'dashboard',
                    component: () => import('@/views/pro/DashBoard.vue'),
                    meta:
                    {
                        requiresAuth:true
                    }

            },
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/pro/LoginPage.vue'),
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('@/views/pro/RegisterPage.vue'),

            },
            {
                path: '',
                name: 'welcome',
                component: () => import('@/views/pro/LandingPage.vue'),
            }
        ]
    },];
}
else {
 routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
          
            {
              path: '',
              name: 'mainform',
              component: () => import('@/views/MainForm.vue'),
              
          },
          {
            path: '/clsfd/:id',
            name: 'clsfd',
            component: () => import('@/views/MainForm.vue'),
            strict: false,
        }]
    },
    {

        path: '/admin',
        component: AdmLayout,
        children: [
            {
                
                    path: '',
                    name: 'admin_dashboard',
                    component: () => import('@/views/adm/DashBoard.vue'),
                    
                
            }
        ],
        meta: { requiresAuth: true},
    },
   
    {
            path: '/pages/notfound',
            name: 'notfound',
            component: () => import('@/views/NotFound.vue'),
    }
    ]
}

const router = createRouter({
    history: createWebHistory(),
    routes: routes
        });



router.beforeEach((to, from, next) => {
 
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    
    if (requiresAuth) {

        if (!firebaseAuth.currentUser) next({ name: 'login' });
       
        firebaseAuth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              console.log(idTokenResult);
              if (!idTokenResult.claims.pro) {
                next({ name: 'login' })
              } else {
                next()
              }
            });
        
      }
    else {
      next();
    }
  });
  
  router.beforeResolve((to, from, next) => {
  
    if (to.name) {
  //       NProgress.start()
    }
    next()
  });
  
  router.afterEach(() => {
  //  NProgress.done()
  });

    export default router;
